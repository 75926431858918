@use "src/mixins" as *;
@use "src/variables" as *;

.preloader-box {
  width: 100%;

  .preloader-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      width: 35px;
    }
  }

  svg.preloader {
    stroke: $green;
  }
}