@use "src/mixins" as *;
@use "src/variables" as *;

@import "./fonts.scss";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    min-width: 300px;
  }
}
table {
  border-collapse:collapse;
}
.only-mobile {
  display: none !important;

  @include media($mediapoint-very-small, $mediapoint-small) {
    display: block !important;
  }
}

.only-desk {
  @include media($mediapoint-very-small, $mediapoint-small) {
    display: none !important;
  }
}

.divider {
  height: 1px;
  background-color: #CCCCCC;
  margin: 25px 0px 20px 0px;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  text-align: center;
}

.container-width-100 {
  margin: 0 auto;
  width: 100%;
  position: relative;

  &.padding-top-header {
    padding-top: 50px;
  }

  &.padding-top-header-100 {
    padding-top: 100px;
  }
}

.strikethrough-text {
  color: #a7a7a7 !important;
  text-decoration: line-through;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.color-blue {
  color: #0094AA !important;
  font-weight: bold;
}

.container-page {
  margin: 0 auto;
  //width: 100%;
  max-width: 1200px;
  position: relative;

  @include media($mediapoint-medium, $mediapoint-large) {
    margin: 0 25px 0 25px !important;
  }

  @include media($mediapoint-very-small, $mediapoint-medium) {
    min-width: 280px;
  }
}

.columns-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  //  flex-wrap: wrap;
  min-height: 47px;
  justify-content: space-between;
  padding-right: 50px;

  @include media($mediapoint-very-small, $mediapoint-small) {
    padding-right: 0px;
  }

  svg {
    min-width: 20px;
    margin-right: 20px;
  }

  .img-point {
    margin-right: 15px;

    &img {
      max-width: 48px;
      margin: 0 auto;
    }
  }

  p, h3 {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    margin: 15px 0;
    color: #1D1D1B;

    @include media($mediapoint-very-small, $mediapoint-medium) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  p.small {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #1D1D1B;
    line-height: 20px;
    text-align: left;
    margin: 15px 0;

    @include media($mediapoint-very-small, $mediapoint-medium) {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

/*
@import "./lib/materialize/materialize-src/sass/materialize.scss";
*/

.bg-grey {
  @include media($mediapoint-very-small, $mediapoint-medium) {
    background: linear-gradient(89.58deg, #4E4E4E 0%, #6D6D6D 88.94%);
  }

  background: inherit;
}

.breakpoint-1 {
  position: absolute;
  top: 70%;
  left: -250px;
}

// ---
.black-button {
  background-color: #1D1D1B;
  color: #fff !important;
  border-radius: 24px;
  padding: 15px 38px;
  cursor: pointer;
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  border: none;
  max-width: 236px;
  @extend %d-flex-row;
  @extend %d-flex-center-center;
  width: 100%;
  min-width: 150px;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    width: auto;
    max-width: 180px;

    &.w-100 {
      width: 100% !important;
    }
  }

  &:hover {
    background-color: #404040;
  }

  &:focus {
    background-color: #000000;
  }

  img {
    margin-right: 10px;
  }
}

.orange-button-event {
  background: #F7A823;
  box-shadow: 0px 4px 12px rgba(247, 168, 35, 0.48);
  color: #fff !important;
  border-radius: 24px;
  padding: 12px 0px;
  cursor: pointer;
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  border: none;
  @extend %d-flex-row;
  @extend %d-flex-center-center;
  width: calc(100% - 30px);
  min-width: 150px;
  margin: 0 15px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  height: 23px;

  @include media($mediapoint-very-small, $mediapoint-medium) {
  }

  &:hover {
    background-color: #404040;
  }

  &:focus {
    background-color: #000000;
  }

  img {
    margin-right: 10px;
  }

  &.abs-position-button {
    position: absolute;
    bottom: 40px;
  }
}

.carousel-button {
  -webkit-tap-highlight-color: transparent;
  border: 2px solid $blue-hover;
  box-sizing: border-box;
  border-radius: 24px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px auto;
  width: 256px;
  height: 48px;
  cursor: pointer;
  background: white;

  a {
    text-decoration: none;
    color: $blue-hover;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;

    @include media($mediapoint-very-small, $mediapoint-medium) {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 50px;
    }
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    width: 100%;
    text-align: center;
    justify-content: flex-end;
    margin-top: 0;
    height: auto;
    align-items: flex-end;
    border: 0px solid #0094AA;
  }

  &:hover {
    border-color: $blue;
    a {
      color: $blue;
    }
  }

  &:focus {
    border-color: $blue-action;
    color: $blue-action;
  }
}


*
  /*________________noUiSlider styles________________*/
.noUi-value-horizontal {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

#slider_sum .noUi-value-horizontal[style="left: 0%;"] {
  left: 7% !important;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    left: 12% !important;
  }
}

#slider_sum .noUi-value-horizontal[style="left: 100%;"] {
  left: 92% !important;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    left: 87% !important;
  }
}

#slider_day .noUi-value-horizontal[style="left: 0%;"] {
  left: 4% !important;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    left: 6% !important;
  }
}

#slider_day .noUi-value-horizontal[style="left: 100%;"] {
  left: 96% !important;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    left: 92% !important;
  }
}

.noUi-pips-horizontal {
  padding: 0px 0 !important;
  height: 20px !important;
}

.noUi-target {
  background: #FAFAFA !important;
  border: 0 !important;
  box-shadow: 0 !important;
  border-radius: 2px !important;
  height: 4px !important;
}

.noUi-handle:focus,
.noUi-handle-lower:focus {
  outline: none !important;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -30px !important;
}

.noUi-handle {

  border: 0 !important;
  background: transparent !important;
  cursor: pointer !important;
  box-shadow: none !important;

  &:after,
  &:before {
    display: none !important;
  }
}

.noUi-marker-horizontal {
  display: none;
}

.noUi-touch-area {

  border-radius: 100%;
  background-color: #56AF31 !important;
  width: 16px !important;
  height: 16px !important;

  &:focus {
    outline: none;
  }
}

.noUi-connect {
  background: #56AF31 !important;
}

.owl-carousel {
  position: relative;
  //max-width: 1000px;

  .owl-nav {
    position: absolute;
    top: 360px;
    width: 100%;
    display: none;
  }

  .owl-prev {
    position: absolute;
    left: 0;

  }

  .owl-next {
    position: absolute;
    right: 0;
  }

  .owl-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 40px;

    button {
      -webkit-tap-highlight-color: transparent;
      margin: 0;
    }
  }

  .owl-dots .owl-dot.active span {
    width: 11px !important;
    height: 11px !important;
    background: #0094AA !important;
  }

  .owl-dots .owl-dot span {
    width: 5px !important;
    height: 5px !important;
    background: #C4C4C4;
    margin: 5px 3px !important;
  }

  .owl-nav [class*='owl-']:hover {
    background: #fff !important;
    color: #FFF;
    text-decoration: none;
  }

  .owl-nav [class*='owl-']:focus {
    outline: none;
  }
}

.columns-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  //  flex-wrap: wrap;
  min-height: 47px;
  justify-content: space-between;
  padding-right: 50px;

  @include media($mediapoint-very-small, $mediapoint-small) {
    padding-right: 0px;
  }

  svg {
    width: 30px;
    margin-right: 20px;
  }

  .img-point {
    margin-right: 15px;

    &img {
      max-width: 48px;
      margin: 0 auto;
    }
  }

  p {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    margin: 15px 0;

    @include media($mediapoint-very-small, $mediapoint-medium) {
      font-size: 16px;
      line-height: 18px;
    }

    color: #1D1D1B;
  }

  p.small {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    margin: 15px 0;

    @include media($mediapoint-very-small, $mediapoint-medium) {
      font-size: 16px;
      line-height: 18px;
    }

    color: #1D1D1B;
  }
}

.news-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  @include media($mediapoint-medium, $mediapoint-medium-large) {
    justify-content: left;
    flex-wrap: wrap;
  }

  @include media($mediapoint-very-small, $mediapoint-medium) {
    justify-content: center;
    flex-wrap: wrap;
  }

  > div {
    margin-bottom: 15px;
  }

  &_main {
    background: url("./assets/images/news/1.png") 0 0 no-repeat;
    background-size: auto;
    width: 359px;
    height: 290px;
    position: relative;
    margin-right: 25px;
    border-radius: 13px;

    @include media($mediapoint-medium-medium, $mediapoint-medium-large) {
      margin-top: 0px;
    }

    @include media($mediapoint-medium, $mediapoint-medium-medium) {
      width: 410px;
      background-size: cover;
      margin: 0 auto !important;
    }

    @include media($mediapoint-small, $mediapoint-medium) {
      width: 410px;
      margin: 0 auto !important;
      background-size: cover;

    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      width: 100%;
      max-width: 359px;
      margin: 0 auto !important;
      background-size: cover;

    }

    p {
      color: #FFFFFF;
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      bottom: 0;
      position: absolute;
      padding: 15px;
    }
  }

  &_other {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;

    @include media($mediapoint-medium-medium, $mediapoint-medium-large) {
      margin-top: 0px;
    }

    @include media($mediapoint-medium, $mediapoint-medium-medium) {
      margin-top: 15px;
    }

    @include media($mediapoint-very-small, $mediapoint-medium) {
      margin-top: 15px;
    }

    &-box {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: self-start;
      min-height: 85px;
      max-width: 410px;

      @include media($mediapoint-medium-medium, $mediapoint-medium-large) {
        margin: 0px 0;
      }

      @include media($mediapoint-medium, $mediapoint-medium-medium) {
        margin: 15px 0;
      }

      @include media($mediapoint-very-small, $mediapoint-medium) {
        margin: 15px 0;
      }

      img {
        margin-right: 15px;
      }

      > div {
        p {
          margin: 0;
        }

        .news-small_title {
          font-family: Manrope;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */


          /* Achromatic/Gray 3 */

          color: #A7A7A7;
        }

        .news-main_title {
          /* Body/Body M Bold */

          font-family: Manrope;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          /* or 143% */


          /* Achromatic/Black */

          color: #1D1D1B;
        }
      }
    }
  }
}

//body.webp {
//  .description-choose-img {
//    &[data-img="middle"] {
//      background: url("./assets/images/how-to-use-middle.webp") 50% 100% no-repeat;
//    }
//  }
//}

.point-menu-text {
  display: none;
  min-height: 60px;

  @include media($mediapoint-small, $mediapoint-medium) {
    text-align: left;

    p {
      text-align: left;
    }
  }

  span {
    color: #56AF31;
  }

  &.active {
    display: block;
    max-width: 768px;
  }
}

.main-box {
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 25px;

  > img {
    width: 44px;
    margin-right: 20px;
    margin-top: 5px;
  }

  p {
    margin: 0;
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #56AF31;
    padding-right: 20px;
    text-align: left !important;
  }

  @include media($mediapoint-very-small, $mediapoint-medium-large) {
    p {
      font-size: 12px !important;
      padding-right: 0;
    }

    img {
      margin-right: 10px;
    }

    padding: 12px;
  }

  @include media($mediapoint-very-small, $mediapoint-medium) {
    padding: 12px;
    min-width: 205px;
    margin: 20px 0px;
  }

  &.green-box {
    background: #D0F1C1;

    p {
      color: #56AF31;
    }
  }

  &.orange-box {
    background: #d0f1c1;
    p {
      color: #56AF31;
      margin: 0;
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      padding-right: 20px;
      text-align: left !important;
    }
  }

  &.yellow-box {
    background: #FEF4E4;
    p {
      color: #F7A823;
    }
  }

  &.gray-box {
    background: #E0E0E0;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    p {
      color: #1D1D1B;
    }
    span {
      margin-top: 20px;
      font-family: Manrope;
      color: #1D1D1B;
      padding-right: 20px;
    }
  }
}

.forms-block {
  padding: 0 0 30px 0 !important;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    padding: 0 0 0px 0 !important;
  }

  &_title {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    color: #1D1D1B;

    @include media($mediapoint-very-small, $mediapoint-medium) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background: #F0F0F0;
      margin: 0;
      padding: 10px 25px;
    }
  }

  h3 {
    /* Body/Body XL Bold */
    margin: 10px 0;
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    /* Achromatic/Black Primary */

    color: #1D1D1B;
  }

  p.description {
    /* Body/Body L Regular */
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #1D1D1B;
    @include media($mediapoint-very-small, $mediapoint-small) {
      text-align: center;
    }
  }

  &_form {
    background: #F0F0F0;
    border-radius: 12px;
    max-width: 760px;
    min-height: 220px;
    padding: 25px;
    position: relative;

    &.form-100 {
      max-width: 1200px !important;
    }

    @include media($mediapoint-very-small, $mediapoint-medium) {
      &.white-mobile-bg {
        background: white;
      }
    }

    input:focus,
    textarea:focus {
      outline: none !important;
      border: 1px solid #56AF31 !important;
      color: #4F4F4F;
      background-color: #DFF4F7;
    }

    &-input {
      position: relative;

      label {
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1D1D1B;
        width: 100%;
        display: block;
        text-align: left;
        padding: 5px 0 5px 15px;

        @include media($mediapoint-very-small, $mediapoint-small) {
          text-align: center;
          padding: 5px 0 5px 0px !important;
        }
      }

      input {
        /* Achromatic/White */
        height: 48px;
        background: #FFFFFF;
        /* Achromatic/Gray 0D */
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        /* Input Shadow */
        width: 100%;
        padding: 0 15px;
        box-shadow: 0px 4px 12px rgba(29, 29, 27, 0.04);
        border-radius: 12px;
        /* Body/Body XL Regular */
        color: #A7A7A7;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;

        @include media($mediapoint-very-small, $mediapoint-small) {
          text-align: center;
        }
      }

      svg {
        position: absolute;
        top: 40px;
        right: 15px;
      }
    }

    &-input-file {
      width: 100%;

      label {
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1d1d1b;
        width: fit-content;
        display: block;
        padding: 5px 0 5px 15px;

        @include media($mediapoint-very-small, $mediapoint-small) {
          margin: auto;
          padding: 5px 0 5px 0px !important;
        }
      }

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
      }

      .input-file {
        width: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        min-height: 100px;
        width: 100%;
      }

      .input-file + label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 10px 24px !important;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        width: 100%;
        box-shadow: 0px 4px 12px rgba(29, 29, 27, 0.04);
        border-radius: 12px;
        color: #a7a7a7;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        margin: 10px 0;

        &.parsley-error {
          background-color: white;
          border: 1px solid #e74c3c !important;
        }

        @include media($mediapoint-very-small, $mediapoint-small) {
          text-align: center;
        }
      }

      .input-file:focus + label {
        outline: none !important;
        border: 1px solid #56af31 !important;
        color: #4f4f4f;
        background-color: #dff4f7;
      }
    }

    &-select {
      position: relative;

      label {
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1D1D1B;
        width: 100%;
        display: block;
        text-align: left;
        padding: 5px 0 5px 15px;

        @include media($mediapoint-very-small, $mediapoint-medium) {
          text-align: center;
          padding: 5px 0 5px 0px !important;
        }

      }

      select {
        /* Achromatic/White */
        height: 48px;
        background: #FFFFFF;
        /* Achromatic/Gray 0D */
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        /* Input Shadow */
        width: 100%;
        padding: 0 15px;
        box-shadow: 0px 4px 12px rgba(29, 29, 27, 0.04);
        border-radius: 12px;
        /* Body/Body XL Regular */
        color: #A7A7A7;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        outline: none;

        @include media($mediapoint-very-small, $mediapoint-small) {
          text-align: center;
        }

        option {
          color: #1D1D1B;
          background: #FFFFFF;
          /* Achromatic/Gray 0D */

          border: 1px solid #E0E0E0;
          box-sizing: border-box;
          /* Shadows/Input Default Shadow */

          box-shadow: 0px 4px 12px rgba(29, 29, 27, 0.04);
          border-radius: 12px;
        }
      }

      svg {
        position: absolute;
        top: 40px;
        right: 15px;
      }
    }

    &-textarea {
      width: 100%;

      label {
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1D1D1B;
        width: 100%;
        display: block;
        text-align: left;
        padding: 5px 0 5px 15px;

        @include media($mediapoint-very-small, $mediapoint-medium) {
          text-align: center;
          padding: 5px 0 5px 0px !important;
        }
      }

      textarea {
        /* Achromatic/White */
        height: 150px;
        background: #FFFFFF;
        /* Achromatic/Gray 0D */
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        /* Input Shadow */
        width: 100%;
        padding: 10px 15px;
        box-shadow: 0px 4px 12px rgba(29, 29, 27, 0.04);
        border-radius: 12px;
        /* Body/Body XL Regular */
        color: #A7A7A7;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        resize: none;
        line-height: 28px;

        @include media($mediapoint-very-small, $mediapoint-small) {
          text-align: center;
        }
      }
    }

    button, .button-form {
      /* Chromatic/Green Primary */
      width: 260px !important;
      height: 44px;
      background: #56AF31;
      /* Primary Button Shadow */
      display: flex;
      align-items: center;
      text-align: center;
      box-shadow: 0px 4px 14px rgba(80, 163, 46, 0.32);
      border-radius: 24px;
      font-family: Manrope;
      -webkit-tap-highlight-color: transparent;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      justify-content: center;
      border: 0px;
      margin: 25px 0;
      cursor: pointer;

      @include media($mediapoint-very-small, $mediapoint-small) {
        margin: 0px auto !important;
      }

      &:hover {
        background-color: #7BD057;
      }

      &:focus {
        background-color: #3F8024;
      }
    }

    .choose-time-call {
      max-width: 470px;

      @include media($mediapoint-very-small, $mediapoint-medium) {
        margin: 0 auto;
      }

      &_input {
        text-align: center;
        background: #E0E0E0;
        border-radius: 12px;
        height: 80px;
        display: flex;
        align-items: center;

        justify-content: center;
        margin-top: 10px;
        pointer-events: none;

        &.active {
          background: #D0F1C1;
          pointer-events: initial;

          input {
            background: #FFFFFF;
          }
        }

        input {
          width: 50%;
          background: #F0F0F0;
          text-align: center;
        }
      }

      &_choose {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        flex-wrap: wrap;

        &-box {
          background: #E0E0E0;
          border-radius: 12px;
          height: 48px;
          width: 226px;
          margin: 10px 0;
          cursor: pointer;

          @include media($mediapoint-very-small, $mediapoint-medium) {
            width: 48%;
          }

          &.active {
            background: #56AF31;

            p {
              color: white;
            }
          }

          &:hover {
            background: #A7A7A7;

            p {
              color: white;
            }
          }

          p {
            /* Body/Body L Regular */

            font-family: Manrope;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            /* identical to box height, or 125% */

            text-align: center;

            /* Achromatic/Gray 2 */

            color: #4F4F4F;

          }
        }
      }
    }
  }

  .grid-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;

    @include media($mediapoint-very-small, $mediapoint-small) {
      grid-template-columns: 1fr;
      gap: 16px;
    }

  }

  .row-input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media($mediapoint-very-small, $mediapoint-small) {
      flex-direction: column;
      padding: 0 0px;

      &.row-count-2 > div {
        width: 100% !important;

      }
    }

    &.row-margin {
      margin-bottom: 15px;
    }

    &.row-count-2 > div {
      width: 48%;
    }

    &.row-count-1 > div {
      // width: 100%;
    }
  }
}

/*Шаблон для форм*/
.forms-block {
  padding: 0 0 30px 0 !important;
  @include media($mediapoint-very-small, $mediapoint-medium) {
    padding: 0 0 0px 0 !important;
  }

  &_title {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    color: #1D1D1B;
    @include media($mediapoint-very-small, $mediapoint-medium) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background: #F0F0F0;
      margin: 0;
      padding: 10px 25px;
    }

  }

  h3 {
    /* Body/Body XL Bold */
    margin: 10px 0;
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */


    /* Achromatic/Black Primary */

    color: #1D1D1B;
  }

  p.description {
    /* Body/Body L Regular */

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #1D1D1B;
    @include media($mediapoint-very-small, $mediapoint-small) {
      text-align: center;
    }
  }

  &_form {
    background: #F0F0F0;
    border-radius: 12px;
    max-width: 760px;
    min-height: 220px;
    padding: 25px;
    position: relative;

    &.form-100 {
      max-width: 1200px !important;
    }

    @include media($mediapoint-very-small, $mediapoint-medium) {
      &.white-mobile-bg {
        background: white;
      }

    }

    input:focus,
    textarea:focus {
      outline: none !important;
      border: 1px solid #56AF31 !important;
      color: #4F4F4F;
      background-color: #DFF4F7;
    }

    &-input {
      position: relative;

      label {
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1D1D1B;
        width: 100%;
        display: block;
        text-align: left;
        padding: 5px 0 5px 15px;
        @include media($mediapoint-very-small, $mediapoint-small) {
          text-align: center;
          padding: 5px 0 5px 0px !important;
        }

      }

      input {
        /* Achromatic/White */
        height: 48px;
        background: #FFFFFF;
        /* Achromatic/Gray 0D */
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        /* Input Shadow */
        width: 100%;
        padding: 0 15px;
        box-shadow: 0px 4px 12px rgba(29, 29, 27, 0.04);
        border-radius: 12px;
        /* Body/Body XL Regular */
        color: #A7A7A7;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        @include media($mediapoint-very-small, $mediapoint-small) {
          text-align: center;
        }
      }

      svg {
        position: absolute;
        top: 40px;
        right: 15px;
      }
    }

    &-select {
      position: relative;

      label {
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1D1D1B;
        width: 100%;
        display: block;
        text-align: left;
        padding: 5px 0 5px 15px;
        @include media($mediapoint-very-small, $mediapoint-medium) {
          text-align: center;
          padding: 5px 0 5px 0px !important;
        }

      }

      select {
        /* Achromatic/White */
        height: 48px;
        background: #FFFFFF;
        /* Achromatic/Gray 0D */
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        /* Input Shadow */
        width: 100%;
        padding: 0 15px;
        box-shadow: 0px 4px 12px rgba(29, 29, 27, 0.04);
        border-radius: 12px;
        /* Body/Body XL Regular */
        color: #A7A7A7;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        outline: none;
        @include media($mediapoint-very-small, $mediapoint-small) {
          text-align: center;
        }

        option {
          color: #1D1D1B;
          background: #FFFFFF;
          /* Achromatic/Gray 0D */

          border: 1px solid #E0E0E0;
          box-sizing: border-box;
          /* Shadows/Input Default Shadow */

          box-shadow: 0px 4px 12px rgba(29, 29, 27, 0.04);
          border-radius: 12px;
        }
      }

      svg {
        position: absolute;
        top: 40px;
        right: 15px;
      }
    }

    &-textarea {
      width: 100%;

      label {
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1D1D1B;
        width: 100%;
        display: block;
        text-align: left;
        padding: 5px 0 5px 15px;
        @include media($mediapoint-very-small, $mediapoint-medium) {
          text-align: center;
          padding: 5px 0 5px 0px !important;
        }
      }

      textarea {
        /* Achromatic/White */
        height: 150px;
        background: #FFFFFF;
        /* Achromatic/Gray 0D */
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        /* Input Shadow */
        width: 100%;
        padding: 10px 15px;
        box-shadow: 0px 4px 12px rgba(29, 29, 27, 0.04);
        border-radius: 12px;
        /* Body/Body XL Regular */
        color: #A7A7A7;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        resize: none;
        line-height: 28px;
        @include media($mediapoint-very-small, $mediapoint-small) {
          text-align: center;
        }
      }
    }

    button, .button-form {
      /* Chromatic/Green Primary */
      width: 260px !important;
      height: 44px;
      background: #56AF31;
      /* Primary Button Shadow */
      display: flex;
      align-items: center;
      text-align: center;
      border-radius: 24px;
      font-family: Manrope;
      -webkit-tap-highlight-color: transparent;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      justify-content: center;
      border: 0px;
      margin: 25px 0;
      cursor: pointer;
      @include media($mediapoint-very-small, $mediapoint-small) {
        margin: 0px auto !important;
      }

      @include media($mediapoint-very-small, $mediapoint-medium) {
        height: 50px;
      }

      &:hover {
        background-color: #7BD057;
      }

      &:focus {
        background-color: #3F8024;
      }
    }

    .button-square {
      width: 170px !important;
      height: 48px;
      border-radius: 12px;
      margin: 0px 10px 10px 0px !important;
    
    }

    .button-gray {
      background-color: #E0E0E0;
      box-shadow: none;
      color: #4F4F4F;

      &:hover {
        background-color: #d1cbcb;
      }
      
    }

    .choose-time-call {
      max-width: 470px;
      @include media($mediapoint-very-small, $mediapoint-medium) {
        margin: 0 auto;
      }

      &_input {
        text-align: center;
        background: #E0E0E0;
        border-radius: 12px;
        height: 80px;
        display: flex;
        align-items: center;

        justify-content: center;
        margin-top: 10px;
        pointer-events: none;

        &.active {
          background: #D0F1C1;
          pointer-events: initial;

          input {
            background: #FFFFFF;
          }
        }

        input {
          width: 50%;
          background: #F0F0F0;
          text-align: center;
        }
      }

      &_choose {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        flex-wrap: wrap;


        &-box {
          background: #E0E0E0;
          border-radius: 12px;
          height: 48px;
          width: 226px;
          margin: 10px 0;
          cursor: pointer;
          @include media($mediapoint-very-small, $mediapoint-medium) {
            width: 48%;
          }

          &.active {
            background: #56AF31;

            p {
              color: white;
            }
          }

          &:hover {
            background: #A7A7A7;

            p {
              color: white;
            }
          }

          p {
            /* Body/Body L Regular */
            font-family: Manrope;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #4F4F4F;
          }
        }
      }
    }

  }

  .row-input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media($mediapoint-very-small, $mediapoint-small) {
      flex-direction: column;
      padding: 0 0px;

      &.row-count-2 > div {
        width: 100% !important;

      }
    }

    &.row-margin {
      margin: 15px 0px;

      @include media($mediapoint-very-small, $mediapoint-small) {
        margin: 16px 0px 24px;
      }
    }

    &.row-count-2 > div {
      width: 48%;
    }

    &.row-count-1 > div {
      // width: 100%;
    }
  }
}
