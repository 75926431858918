@use "src/mixins" as *;
@use "src/variables" as *;

.main-carousel-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-height: 650px;
  margin: 80px 25px 75px 0;

  @include media($mediapoint-medium, $mediapoint-large) {
    margin: 0 25px 0;
  }

  @include media($mediapoint-very-small, $mediapoint-medium) {
    display: none;
  }

  a {
    display: block;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__slide {
    display: block;
    visibility: hidden;
    height: 0;
  }
}
