@use "src/mixins" as *;
@use "src/variables" as *;

.block-6 {
  background: linear-gradient(89.58deg, #4e4e4e 0%, #6d6d6d 88.94%);
  text-align: center;
  padding: 80px 45px 40px;

  @include media($mediapoint-small, $mediapoint-medium) {
    display: none;
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    display: none;
  }

  h2 {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    color: #f7bc23;
    margin: 0;
  }

  p {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #f7bc23;
    margin: 15px 0 0 0;
  }

  &__button {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    border: none;
    margin-top: 40px;
    color: white;
    background-color: $green;
    border-radius: 24px;
    text-decoration: none;
    padding: 12px 16px;
    cursor: pointer;

    &:hover {
      background-color: $green-hover;
    }
  }
}
