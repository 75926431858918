@use "src/mixins" as *;
@use "src/variables" as *;

.init-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &-spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: $green transparent $green2 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
  }
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}