@use "src/variables" as *;
@use "src/mixins" as *;

.modal-bg {
  opacity: 0;
  transition: 0.2s linear;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100001;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  overflow: auto;
  display: block;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.toggle-information {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 14px;
  transition: 0.2s linear;
  position: fixed;
  top: 50%;
  width: calc(100% - 62px);
  left: 50%;
  padding: 0px 16px 16px 16px;
  z-index: 100003;
  opacity: 0;
  visibility: hidden;
  max-width: 450px;
  transform: translate(-50%, -50%);

  &.show {
    opacity: 1;
    visibility: visible;
  }

  //
  //@include media($mediapoint-small, $mediapoint-medium) {
  //  position: fixed;
  //  width: calc(100% - 84px);
  //  top: 50%;
  //  min-height: 195px;
  //  left: 50%;
  //  transform: translate(-50%, -50%);
  //}
  //
  //@include media($mediapoint-very-small, $mediapoint-small) {
  //  position: fixed;
  //  width: 100%;
  //  min-height: 195px;
  //  bottom: 0;
  //  top: auto;
  //  left: 0;
  //  margin-bottom: 0;
  //  box-sizing: border-box;
  //
  //  //.toggle-information-header {
  //  //
  //  //}
  //}

  .click-information-toggle {
    cursor: pointer;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;

    svg {
      width: 17px;
      height: 17px;
    }

    .toggle-information-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.3em;
      font-family: 'Manrope';
      color: #1D1D1B;
      margin: 0;
    }
  }

  .toggle-information-body {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4em;
    margin: 10px 0 0 0;
    color: #1D1D1B;

    b {
      margin: 10px 20px;
      display: contents;
    }
  }
}