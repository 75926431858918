@use "../../mixins" as *;
@use "../../variables" as *;

.carousel {
  width: 100%;
  display: none;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    display: block;
    margin: 25px 0;
  }

  .swiper-wrapper {
    overflow: visible !important;
  }

  .swiper-slide {
    width: 85%;
  }

  &__banner {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
