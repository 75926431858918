@use "../../mixins" as *;
@use "../../variables" as *;

.feedback-block {
  width: 100%;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    margin: 25px 0;
  }

  .pagination_block {
    display: flex;
    justify-content: center;
    align-items: center;

    @include media($mediapoint-very-small, $mediapoint-small) {
      pointer-events: none;
      cursor: default;
    }
  }

  .swiper-pagination-bullet {
    width: 5px !important;
    height: 5px !important;
    background: #C4C4C4 !important;
    margin: 5px 3px !important;

    &-active {
      width: 11px !important;
      height: 11px !important;
      background: #0094AA !important;
    }
  }

  .item {
    //margin-right: 25px ;
  }

  .feedback-person {
    >img {
      width: 52px !important;
      height: 52px;
      margin-right: 10px;
    }

    .feedback-person__personal {
      img {
        height: 24px;
        width: 114px;
      }
    }

    .feedback-person__info {
      display: flex;
      align-items: center;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    p {
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #1D1D1B;
      margin: 5px 0;

      @include media($mediapoint-very-small, $mediapoint-small-360) {
        font-size: 14px;
      }

      span {
        color: #A7A7A1;
        padding: 0 0px;
        font-weight: 100;
        margin-left: 10px;
      }
    }
  }

  .feedback-content {
    background: #0094AA;
    box-shadow: 0px 4px 8px rgba(0, 148, 170, 0.12);
    border-radius: 0px 12px 12px 12px;

    p {
      font-family: Manrope;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      color: #FFFFFF;
      padding: 25px;
      text-align: left;

      @include media($mediapoint-very-small, $mediapoint-medium) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
