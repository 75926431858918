@use "src/mixins" as *;
@use "src/variables" as *;

.message-only-mobile {
  display: flex;
  height: 850px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @include media($mediapoint-very-small, $mediapoint-large) {
    height: 650px;
  }

  &__image {
    height: 170px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  & > p {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 300;
    margin: 15px 0 0;

    @include media($mediapoint-very-small, $mediapoint-small-360) {
      margin: 0;
    }
  }
}
