@use "src/mixins" as *;
@use "src/variables" as *;

.posts-blog {
  &__post {
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    height: auto;

    &-img {
      width: 100%;
      height: 200px;

      @include media($mediapoint-small, $mediapoint-medium - 1) {
        height: 260px;
      }

      @include media($mediapoint-very-small, $mediapoint-small-360) {
        height: 220px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-content {
      margin: 17px 16px 27px;

      & > p {
        font-family: Manrope;
        font-style: normal;
        font-size: 13px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &-link {
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #56af31;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
