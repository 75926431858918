/*_______________fonts_______________*/

@font-face {
  font-display: swap;
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-Bold.eot');
  src: url('./assets/fonts/Manrope-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Manrope-Bold.woff2') format('woff2'),
  url('./assets/fonts/Manrope-Bold.woff') format('woff'),
  url('./assets/fonts/Manrope-Bold.ttf') format('truetype'),
  url('./assets/fonts/Manrope-Bold.svg#Manrope-Bold') format('svg');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-display: swap;
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-ExtraBold.eot');
  src: url('./assets/fonts/Manrope-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Manrope-ExtraBold.woff2') format('woff2'),
  url('./assets/fonts/Manrope-ExtraBold.woff') format('woff'),
  url('./assets/fonts/Manrope-ExtraBold.ttf') format('truetype'),
  url('./assets/fonts/Manrope-ExtraBold.svg#Manrope-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-display: swap;
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-Regular.eot');
  src: url('./assets/fonts/Manrope-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Manrope-Regular.woff2') format('woff2'),
  url('./assets/fonts/Manrope-Regular.woff') format('woff'),
  url('./assets/fonts/Manrope-Regular.ttf') format('truetype'),
  url('./assets/fonts/Manrope-Regular.svg#Manrope-Regular') format('svg');
  font-weight: normal;
  font-style: normal;

}
