@mixin media($width-1, $width-2) {
  @if($width-1 !=$width-2) {
    @media (min-width: #{$width-1 + 1 + 'px'}) and (max-width: #{$width-2 + 'px'}) {
      @content
    }
  }

  @if($width-1 ==$width-2) {
    @media (min-width: #{$width-1 + 1 + 'px'}) {
      @content
    }
  }
}