@use "src/mixins" as *;
@use "src/variables" as *;

.blog-section {
  padding: 32px 0px 40px;
  background-color: #f7bc23;

  @include media($mediapoint-very-small, $mediapoint-small) {
    padding: 40px 0px 36px 0px;
  }

  h2 {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    color: #1d1d1b;
    margin-bottom: 30px;

    @include media($mediapoint-very-small, $mediapoint-small) {
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 28px;
    }

    .mobile-text {
      display: none;

      @include media($mediapoint-very-small, $mediapoint-small) {
        display: inline;
      }
    }
  }

  &__main-container {
    @include media($mediapoint-very-small, $mediapoint-small) {
      margin: 0 25px 0 25px;
    }

    margin: 0 45px 0 45px;
  }

  &__button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    & > a {
      color: white;
      background-color: $green;
      border-radius: 24px;
      text-decoration: none;
      padding: 12px 16px;
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;

      &:hover {
        background-color: $green-hover;
      }
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      margin-top: 38px;
    }
  }
}
