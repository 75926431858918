@use "src/mixins" as *;
@use "src/variables" as *;

.green-button {
  position: relative;
  z-index: 1;
  background-color: $green;
  color: #fff !important;
  box-shadow: 0px 4px 14px rgba(80, 163, 46, 0.32);
  border-radius: 24px;
  padding: 12px 38px;
  cursor: pointer;
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  border: none;
  max-width: 186px;
  @extend %d-flex-row;
  @extend %d-flex-center-center;
  width: 50%;
  min-width: 150px;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    width: auto;
    max-width: 180px;

    &.w-100 {
      width: 100% !important;
      text-align: center;
    }
  }

  @include media($mediapoint-very-small, $mediapoint-medium) {
    padding: 15px 38px;
  }

  &.disabled {
    pointer-events: none;
    background-color: $green-action;
  }

  &:hover {
    background-color: $green-hover;
  }

  &:focus {
    background-color: $green-action;
  }

  img {
    margin-right: 10px;
  }
}