@use "src/mixins" as *;
@use "src/variables" as *;

.block-3 {
  background: #F0F0F0;
  position: relative;

  @include media($mediapoint-very-small, $mediapoint-small) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin-top: -29px;
  }
}
