@use "src/mixins" as *;
@use "src/variables" as *;

.block-5 {
  min-height: 200px;
  padding: 10px 0px 30px 0px;
  text-align: center;

  @include media($mediapoint-small, $mediapoint-medium) {}

  @include media($mediapoint-small-360, $mediapoint-small) {
    padding: 25px;

    .block-text {
      display: none;
    }

    .block-img {
      width: 350px;
    }

    .block-button {
      width: 300px;
    }
  }

  @include media($mediapoint-very-small, $mediapoint-small-360) {
    padding: 0 15px;

    .block-text {
      display: none;
    }

    .block-img {
      width: 350px;
    }

    .block-button {
      width: 300px;
    }
  }

  h2 {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #1D1D1B;

    @include media($mediapoint-very-small, $mediapoint-medium) {
      justify-content: center;
    }
    .mobile {
      display: none;
      @include media($mediapoint-very-small, $mediapoint-medium) {
        display: inline;
      }
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      margin: 0;
      font-size: 20px;
      line-height: 28px;

    }
  }

  &-main-container {
    @include media($mediapoint-very-small, $mediapoint-small) {
      margin: 0 0px 0 0px;
    }

    margin: 0 45px 0 45px;

  }
}

