@use "src/variables" as *;
@use "src/mixins" as *;

header {
	padding-top: 25px;
	position: fixed;
	width: 100%;
	height: 70px;
	top: 0;
	z-index: 10000;
	background-color: white;

	@include media($mediapoint-very-small, $mediapoint-small) {
		position: fixed;
		top: 0;
		height: 70px;
		background-color: white;
		z-index: 10000;
		width: 100%;
		margin: 0;
		padding-top: 25px;

		&.active {
			background-color: #f0f0f0 !important;
		}
	}

	.header-left {
		display: flex;
		align-items: center;
		@include media($mediapoint-very-small, $mediapoint-small) {
			justify-content: space-between;
			width: 100%;
		}
		.orange-button {
			display: none;
			@include media($mediapoint-very-small, $mediapoint-small) {
				margin-right: 10px;
				display: flex;
				order: 3;
			}
		}

	}

	.header-right {
		display: flex;
		align-items: center;

		.orange-button {
			@include media($mediapoint-very-small, $mediapoint-small) {
				display: none;
			}
		}
	}

	>div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.part-menu {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: calc(100% - 0px);
			z-index: 1000;

			&.active {
				.logotype {
					position: fixed;
					left: 105px;
					top: 28px;

					@include media($mediapoint-very-small, $mediapoint-small) {
						position: relative;
						left: 0px;
						top: 0px;
					}
				}

				.header-location {
					display: none;
				}
			}

			@include media($mediapoint-medium, $mediapoint-large) {
				padding: 0 20px;
			}

			@include media($mediapoint-small-360, $mediapoint-medium) {
				padding: 0 20px;

			}

			@include media($mediapoint-very-small, $mediapoint-small-360) {
				padding: 0 10px;

			}

			.flex-grow {
				flex-grow: 1;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
			}

			@include media($mediapoint-very-small, $mediapoint-small) {
				justify-content: space-between;
				width: 100%;

				.flex-grow {
					flex-grow: 0;
				}

				&.active {

					.flex-grow,
					.enter-in-account {
						display: none;
					}
				}
			}
		}
	}

	.logotype {
		height: 48px;
	}

	.header-location {
		font-family: 'Manrope';
		font-weight: 400;
		font-size: 14px;
		line-height: 1.25em;
		display: flex;
		align-items: center;
		color: #A7A7A7;
		margin-left: 20px;

		img {
			margin-right: 6px;
		}

		@include media($mediapoint-very-small, $mediapoint-small) {
			font-size: 10px;
			margin-left: 9px;
		}
	}

	.nav-bar-icon {
		margin: 0px 0px 0 30px;
		cursor: pointer;
		padding: 0px 0px;
		position: relative;
		height: 28px;
		width: 28px;

		-webkit-tap-highlight-color: transparent;

		@include media($mediapoint-very-small, $mediapoint-small) {
			margin: 0px 0px 0 0px;
		}

		&.active {
			position: fixed;
			left: 18px;
			top: 38px;
			transition: 0.5s;

			@include media($mediapoint-very-small, $mediapoint-small) {
				position: relative;
				left: 0px;
				top: 0px;
			}

			.nav-bar-icon_line:nth-child(1) {
				transform: rotate(45deg);
				position: absolute;
				top: 5px;
			}

			.nav-bar-icon_line:nth-child(2) {
				transform: rotate(135deg);
				position: absolute;
				top: 5px;
			}

			.nav-bar-icon_line:nth-child(3) {
				height: 0;
			}
		}

		&:hover {
			.nav-bar-icon_line {
				background: #A7A7A7;
			}
		}

		&:focus {
			.nav-bar-icon_line {
				background: #4F4F4F;
			}
		}

		&_line {
			height: 2px;
			width: 28px;
			margin: 5px 0;
			background: #CCCCCC;

		}

		&_line:nth-child(1) {
			position: absolute;
			top: 1px;
			transition: 0.5s;
		}

		&_line:nth-child(2) {
			position: absolute;
			top: 8px;
			transition: 0.5s;
		}

		&_line:nth-child(3) {
			position: absolute;
			top: 16px;
			transition: 0.5s;
		}
	}

	.orange-button {
		-webkit-tap-highlight-color: transparent;
		background: $orange;
		border-radius: 24px;
		width: 174px;
		height: 48px;
		text-align: center;
		font-family: Manrope;
		font-style: normal;
		font-weight: 800;
		font-size: 16px;
		line-height: 20px;
		color: #FFFFFF;
		text-decoration: none;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-right: 50px;

		&:hover {
			background: $orange-hover;
		}

		&:focus {
			background: $orange-action;
		}
	}

	.enter-in-account {
		margin: 0 0px 0 0;
		-webkit-tap-highlight-color: transparent;

		svg path {
			fill: $grey;
		}

		&:hover {
			svg path {
				fill: $grey-hover;
			}
		}

		&:focus {
			svg path {
				fill: $grey-action;
			}
		}

		@include media($mediapoint-very-small, $mediapoint-medium) {
			margin: 0 0px 0 0;
		}

	}

	@include media($mediapoint-small-360, $mediapoint-small) {
		.logotype {
			-webkit-tap-highlight-color: transparent;
			margin: 0 !important;
		}

		.menu {
			order: 4;
		}

		.logotype {
			line-height: 80px;

			img {
				margin-left: 5px;

				width: 90px;
			}
		}

		.enter-in-account {
			order: 3;
			line-height: 0px;
			margin-right: 20px;
		}
	}

	@include media($mediapoint-very-small, $mediapoint-small-360) {
		.menu {
			order: 4;
			margin-right: 5px;
		}

		.logotype {
			line-height: 80px;
			margin: 0 !important;

			img {
				margin-left: 5px;
				width: 90px;
			}
		}

		.enter-in-account {
			margin-right: 16px;
			order: 3;
			line-height: 0px;
		}
	}
}

#main-page {
	header .menu {
		display: none;

		@include media($mediapoint-very-small, $mediapoint-medium) {
			display: block !important;
		}
	}

	.desktop-second-menu {
		opacity: 1;

		@include media($mediapoint-very-small, $mediapoint-medium) {
			display: none;
			padding: 0px 20px 0 20px !important;
		}
	}
}