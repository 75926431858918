@use "src/variables" as *;
@use "src/mixins" as *;

.button-scroll-up {
  position: fixed;
  bottom: 30%;
  right: 0%;
  z-index: 100000;
  cursor: pointer;

  @include media($mediapoint-very-small, $mediapoint-small ) {
    right: 1%;
    width: 60px;
    bottom: 20%;

    img {
      width: 100%;
    }
  }
}

.footer {
  background-color: #1d1d1b;
  position: relative;

  @include media($mediapoint-small, $mediapoint-large ) {
    padding: 0 70px;
  }

  .contacts-info {
    display: flex;
    gap: 40px;

    @include media($mediapoint-small, $mediapoint-large) {
      gap: 20px;
    }

    @include media($mediapoint-very-small, $mediapoint-large) {
      flex-direction: column;
      gap: 10px;
    }

    .time-work {
      margin: 0;
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-align: left;
      line-height: 20px;
      color: #A7A7A1;

      @include media($mediapoint-very-small, $mediapoint-small) {
        text-align: center;
      }
    }
  }

  .img-logotype {
    background: url("../../../../assets/images/logo.svg") 0 0 no-repeat;
    background-size: 100%;
    width: 110px;
    height: 75px;

    @include media($mediapoint-very-small, $mediapoint-small ) {
      margin: 0 auto;
      width: 117px;
    }
  }

  .description {
    max-width: 230px;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    margin-top: 20px;
    color: #A7A7A1;

    @include media($mediapoint-small, $mediapoint-medium ) {
      padding-right: 40px;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      text-align: center;
      margin-top: 0px;
    }
  }

  .contactos {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #A7A7A7;
    text-align: left;
    margin-top: 0;
    margin-bottom: 8px;
    
    @include media($mediapoint-very-small, $mediapoint-small) {
      text-align: center;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .span-block {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @include media($mediapoint-very-small, $mediapoint-small ) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 0;

      a {
        margin: 10px 0px 10px 0px !important;
      }
    }

    a {
      margin: 10px 25px 10px 0px;
    }
  }

  .button-whats-up-active {
    background-color: #79be5ce3 !important;
    transition: all .1s;
  }

  .button-whats-up {
    position: fixed;
    bottom: 3%;
    right: 1%;
    width: 60px;
    height: 60px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    cursor: pointer;
    background-color: #72cd4c;
    border-radius: 50%;
    border: 1px solid rgba(246, 246, 246, 0.89);
    box-shadow: 0px 0px 9px 1px rgba(51, 141, 14, 0.72);
    transition: all .1s;

    &:hover {
      background-color: $green;
      box-shadow: 0px 0px 9px 1px rgba(51, 141, 14, 0.92);
    }

    &:active {
      box-shadow: 0px 0px 9px 1px rgba(51, 141, 14, 0.72);
    }

    .button-caution {
      display: block;
      width: 50%;
      position: absolute;
      right: 40px;
      bottom: 40px;
      user-select: none;
      transition: all .2s;
      transform: scale(1);
    }

    .button-caution-active {
      transform: scale(2.2);
      transition: all .2s;
    }

    .caution-window {
      width: 0px;
      height: 10px;
      position: absolute;
      bottom: 45px;
      right: 46px;
      border: 0px solid #ff8400;
      border-radius: 14px;
      z-index: -10;
      transition: all .4s;
      filter: blur(20px);

      p {
        transition: all .1s;
        color: #fff;
        transform: scale(0);
        filter: blur(20px);
      }

      div {
        transition: all .2s;
        transform: scale(0);
        filter: blur(20px);
      }

      button {
        // transition: all .1s;
        color: #fff;
        background-color: #fff;
        transform: scale(0);
        filter: blur(20px);
      }
    }

    .caution-window-active {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      width: 325px;
      height: 150px;
      z-index: -10;
      border: 3px solid #ff8400;
      box-shadow: 0 0 9px 1px rgb(107, 107, 107);
      border-radius: 14px;
      bottom: 40px;
      right: 41px;
      backdrop-filter: blur(20px);
      background-color: rgba(255, 255, 255, 0.651);
      transition: all .2s;
      padding: 25px;
      transform: scale(1);
      filter: blur(0px);

      @include media($mediapoint-small-360, $mediapoint-small) {
        width: 225px;
        height: 150px;
      }

      @include media($mediapoint-very-small, $mediapoint-small-360) {
        width: 190px;
        height: 130px;
      }

      p {
        font-size: 15px;
        color: #5a5a5a;
        font-family: Manrope;
        transform: scale(1);
        transition: all .1s;
        font-weight: 600;
        margin: 0;
        filter: blur(0px);

        @include media($mediapoint-very-small, $mediapoint-small) {
          font-size: 13px;
        }

        @include media($mediapoint-very-small, $mediapoint-small-360) {
          font-size: 11px;
        }
      }

      div {
        display: flex;
        justify-content: space-between;
        transition: all .4s;
        transform: scale(1);
        filter: blur(0px);
      }

      button {
        border-radius: 12px;
        font-size: 14px;
        padding: 10px 15px;
        cursor: pointer;
        border: 0 $green;
        background-color: $green;
        color: #fff;
        transition: all .1s;
        transform: scale(1);
        filter: blur(0px);

        @include media($mediapoint-very-small, $mediapoint-small) {
          font-size: 13px;
          padding: 7px 6px;
        }

        @include media($mediapoint-very-small, $mediapoint-small-360) {
          font-size: 10px;
          padding: 6px 8px;
        }
      }

      button:hover {
        background-color: #499727;
      }
    }

    &-abs_top {
      top: 2% !important;
    }

    &-abs {
      position: absolute;
      top: 1%;
      right: 1%;
      margin: 0 !important;
      z-index: 1;
      width: 45px;
      height: 45px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      background-color: #72cd4c;
      border: 1px solid rgba(246, 246, 246, 0.89);
      box-shadow: 0px 0px 9px 1px rgba(51, 141, 14, 0.52);
      border-radius: 50%;

      img {
        width: 60%;
      }
    }

    @include media($mediapoint-small, $mediapoint-large ) {
      right: 1%;
      bottom: 2%;
      width: 60px;
      height: 60px;
    }

    @include media($mediapoint-very-small, $mediapoint-small ) {
      right: 2%;
      bottom: 2%;
      width: 50px;
      height: 50px;
    }
  }


  span {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #A7A7A1;

    @include media($mediapoint-very-small, $mediapoint-small ) {
      width: 100%;
    }
  }

  a {
    margin: 0px 0;
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    text-decoration: none;
    color: #A7A7A1;
    display: block;

    @include media($mediapoint-very-small, $mediapoint-small ) {
      text-align: center;
    }
  }

  .load-app-button-only-mobile {
    display: none;
    margin-bottom: 30px;

    @include media($mediapoint-very-small, $mediapoint-small ) {
      display: block;
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 256px;
      height: 48px;
      border-radius: 24px;
      color: #1a1818;
      background-color: #ffffff;

      p {
        font-size: 16px;
      }

      img {
        width: 100px;
      }

      @include media($mediapoint-small, $mediapoint-medium ) {
        width: 160px !important;

        img {
          width: 60px !important;
        }

        p {
          font-size: 10px !important;
        }
      }
    }

    &.only-desk {
      display: block;
      margin: 20px 0;

      a {
        width: 256px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
      }

      img {
        width: 120px;
      }

      @include media($mediapoint-very-small, $mediapoint-small ) {
        display: none;
      }
    }
  }

  &-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 10px;

    @include media($mediapoint-medium, $mediapoint-large ) {
      margin: 0 0px 0 0px !important;
    }

    @include media($mediapoint-small, $mediapoint-medium ) {
      margin: 0 0px 0 0px !important;
    }

    @include media($mediapoint-very-small, $mediapoint-small ) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0;
    }

    &-column {
      @include media($mediapoint-very-small, $mediapoint-small ) {
        &:nth-child(1) {
          order: 1;
        }

        &:nth-child(2) {
          order: 3;
        }

        &:nth-child(3) {
          order: 2;
        }

        &:nth-child(4) {
          order: 4;
        }

        &:nth-child(5) {
          order: 5;
        }
      }
    }

    #href-list {
      width: 45%;

      @include media($mediapoint-small, $mediapoint-medium ) {
        width: 70%;
      }

      @include media($mediapoint-very-small, $mediapoint-small ) {
        width: 100%;
      }
    }
  }

  .footer-button {
    -webkit-tap-highlight-color: transparent;
    background: $dark-green;
    border-radius: 24px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 0 20px 0;
    width: 256px;
    height: 48px;
    text-align: center;
    color: $green;
    text-decoration: none;
    cursor: pointer;

    @include media($mediapoint-small, $mediapoint-medium ) {
      width: 160px !important;
      font-size: 13px;
      margin-left: 5px;
    }

    &:hover {
      background: $green-action;
    }

    &:focus {
      background: $dark-green;
      color: $light-green;
    }
  }

  .list-social {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include media($mediapoint-very-small, $mediapoint-small ) {
      justify-content: center;
      margin: 20px 0;
    }

    img {
      margin: 0 10px;
    }
  }

  @include media($mediapoint-very-small, $mediapoint-small ) {

    .footer-block {
      padding-top: 20px;
    }
  }
}