@use "src/mixins" as *;
@use "src/variables" as *;

.block-1 {
  min-height: 540px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
  border-radius: 0px 0px 12px 12px;

  // margin-top: 20px;
  @include media($mediapoint-medium, $mediapoint-large) {
    margin: 0 0px !important;
  }

  @include media($mediapoint-small, $mediapoint-medium) {
    align-items: flex-end;
    justify-content: center;
    height: 100% !important;
    margin-top: 100px;
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    flex-direction: column;
    height: auto;
    padding-bottom: 0px;
    padding-top: 75px;
    align-items: center;
  }

  .calculator-custom-slider {
    &-track-0 {
      // :)
      background: $green !important;
      box-shadow: none !important;
    }

    .calculator-custom-slider-track {
      border-radius: 3px;
      height: 4px;
      box-shadow: inset 0 1px 1px #F0F0F0,0 3px 6px -5px #BBB;
      background: #FAFAFA;
    }

    .calculator-custom-slider-thumb {
      cursor: pointer;
      height: 16px;
      width: 16px;
      top: -6px;
      border-radius: 100%;
      background: $green;
      outline: none;
    }
  }

  .calculator-slider {
    width: 50%;
    margin: 30px 0 50px 25px;
    padding-top: 0px;

    @include media($mediapoint-medium, $mediapoint-large) {
      margin: 0 25px 40px 0;
      width: 50%;
    }

    @include media($mediapoint-small, $mediapoint-medium) {
      width: 80%;
      padding-top: 0px;
      z-index: 100;
      margin: 20px 0 25px 0 !important;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      width: 90%;
      margin: 0px;
      padding-top: 0px !important;
    }

    h1 {
      /* Headers/H1 Header */
      margin-top: 0;
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: $green;

      @include media($mediapoint-small, $mediapoint-medium) {
        text-align: left;
        margin: 10px 0;
      }

      @include media($mediapoint-very-small, $mediapoint-small) {
        justify-content: center;
        font-size: 20px;
        margin: 10px 0;
      }
    }

    h3.title {
      /* Body/Body L Bold */

      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;

      color: #1D1D1B;

      @include media($mediapoint-small, $mediapoint-medium) {
        text-align: left;
        margin: 10px 0;
      }

      @include media($mediapoint-very-small, $mediapoint-small) {
        text-align: center;
        font-size: 16px;
        margin: 10px 0;
      }
    }

    &-box {
      max-width: 660px;
      height: 462px;
      background: #E3F7DA;
      border-radius: 12px;
    }

    .calculator {
      background-color: #E3F7DA;
      padding: 16px;
      border-radius: 12px;

      .calculator__sum {
        background-color: #fff;
        border-radius: 12px;
        padding: 0 16px 8px 16px;
        margin-bottom: 14px;
        transition: background 1s linear 0s;

        &__prompt {
          transition: .2s;
          -webkit-transition: .2s;
          -o-transition: .2s;
          display: block;
          font-family: Manrope;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.3em;
          color: #E74C3C;
          text-align: right;
          margin: 5px 0px;

          &.hidden {
            visibility: hidden;
            margin: 0;
            opacity: 0;
          }
        }

        &.blocked {
          cursor: wait;
          background-color: #f5fbf3 !important;

          #form-sum,
          #form-day {
            pointer-events: none;
          }
        }

        #calculator__sum,
        #calculator__day {
          font-size: 16px;
        }

        .calculator__sum__text-top {
          display: flex;
          justify-content: space-between;

          p.body_m-bold {
            font-family: Manrope;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            color: #4F4F4F;

            @include media($mediapoint-very-small, $mediapoint-small-360) {}
          }

          p.body_l-bold {
            font-family: Manrope;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            color: $green;

            @include media($mediapoint-very-small, $mediapoint-small-360) {}
          }
        }

        .calculator__sum__text-bottom {
          display: flex;
          justify-content: space-between;
          color: #a7a7a7;
          margin-top: 10px;

          p {
            font-family: Manrope;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
          }
        }
      }

      .calculator__info {

        &__loader {
          display: flex;
          align-items: center;
          height: 159px;
          &.collapsed {
            height: 69px;
          }
        }

        .total-label {
          @include media($mediapoint-very-small, $mediapoint-medium) {
            display: flex;
            flex-direction: column;
          }
        }

        & button {
          color: $green;
          border: none;
          -webkit-tap-highlight-color: transparent;
          background-color: transparent;
          cursor: pointer;
          font-family: Manrope;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 28px;
          text-align: center;
          width: 100%;
          height: 35px;
          @extend %d-flex-row;
          @extend %d-flex-center-center;

          &.active {

            color: $green;

            svg path {
              fill: $green;
            }

          }

          svg {
            margin-left: 5px;

            path {
              fill: $green;
            }
          }

          &:hover {
            color: $green;

            svg path {
              fill: $green;
            }
          }
        }

        & p {
          margin-top: 16px;
          margin-bottom: 4px;
          font-family: Manrope;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        }

        span.body_m-bold {
          color: $green;
        }
        .body_m-regular {
          font-weight: normal;
        }

        & div {
          .more-visiable {
            li {
              font-size: 14px;
              font-weight: bold;
              color: #3F8024;
            }

            .body_m-bold {
              color: #3F8024;
            }
          }

          & ul {
            display: flex;
            justify-content: space-between;
            margin: 0 0 6px 0;
            padding: 0;

            .body_m-bold {
              color: $green;
              text-align: right;
              white-space: nowrap;
              font-weight: bold !important;
              margin-top: 0 !important;
              margin-left: 10px;
            }

            .body_m-bold-black {
              color: black;
            }            

            & li {
              list-style-type: none;
              font-family: Manrope;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #4F4F4F;

              >span {
                font-size: 12px;
                line-height: 16px;
                color: #A7A7A7;
              }
            }
          }
        }
      }

      .calculator_column-left {
        border-right: 1px solid #fff;
        padding-right: 16px;
        margin-right: 16px;
        width: 50%;
        min-height: 60px;

        @include media($mediapoint-very-small, $mediapoint-medium-large) {
          width: 100%;
          padding-right: 0px;
          margin-right: 0px;
          min-height: 60px !important;
          height: auto;
          border-right: 0px solid #fff !important;
        }
      }

      .calculator_column-right {
        width: 50%;
        min-height: 60px;

        @include media($mediapoint-very-small, $mediapoint-medium-large) {
          width: 100%;
          min-height: 60px !important;
          height: auto;
        }
      }

      .calculator__button {
        margin: 15px 0;
        -webkit-tap-highlight-color: transparent;
        text-align: center;

        & a {
          & img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            margin-top: -3px;
          }
        }

      }

    }

    /* _________accordion_____________ */

    .button-amount {
      -webkit-tap-highlight-color: transparent;

      .fa-chevron-up {
        transform: rotate(180deg);
        transition: 0.3s ease-out;
      }
    }

    .amount-detalis {
      height: 0;
      overflow: hidden;
      transition: height 0.3s ease-out;
      margin-top: 8px;
      @extend %d-flex-row;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      .amount-container {
        display: flex;
        width: 100%;
        height: 50px;

        @include media($mediapoint-very-small, $mediapoint-medium-large) {
          flex-direction: column !important;
          height: auto !important;
        }

      }

      &.active {
        height: 90px;
        
        @include media($mediapoint-very-small, $mediapoint-medium-large) {
          height: auto !important;
        }        
      }
    }
  }
}
