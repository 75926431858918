@use "src/mixins" as *;
@use "src/variables" as *;

.load-app-button-only-mobile {
    display: none;
    justify-content: center;
    margin-top: 37px;
    margin-bottom: 135px;

    @include media($mediapoint-small, $mediapoint-medium) {
        display: flex;
        margin-left: -45px;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
        display: flex;
        margin-left: 0;
        margin-top: 17px;
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 295px;
        height: 55px;
        border-radius: 56px;
        color: #1a1818;
        background-color: #ffffff;
        text-decoration: none;
        box-sizing: border-box;
        cursor: pointer;
    }

    &__text {
        margin: 0;
        margin-right: 12px;
        font-family: Manrope;
        font-style: normal;
        font-weight: 800;
        font-size: 17px;
        line-height: 20px;
    }

    &__img {
        width: 135px;
    }
}
